import React from "react";
import { Root, Routes } from "react-static";

import "./app.css";

class App extends React.Component {
  render() {
    return (
      <Root>
        <div className="content">
          <Routes />
        </div>
      </Root>
    );
  }
}

export default App;
